<template>
  <div ref="container" class="app-container">
    <avue-crud
      ref="crud"
      class="search-form non-expand-icon"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @row-del="rowDel"
      @row-save="rowSave"
      @row-update="rowUpdate"
    >
      <template #menuLeft>
        <el-button class="text-small icon-add" type="primary" @click="rowAdd">新增退款原因</el-button>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import SaleDateTime from '@/components/saleDateTime'
import defaultImg from '@/views/components/defaultImg'
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import { refundSeasonList as getList, createRefundSeason, updateRefundSeason, deleteRefundSeason } from '@/api/financial/afterSaleProcessingOfOrders'

export default {
  name: 'refundReasonSetting',
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: {
    defaultImg,
    SaleDateTime
  },
  data() {
    return {
      finalData: [],
      resetMergeData: {
        orderItems: [
          {
            column: 'create_time',
            asc: false
          }
        ]
      }
    }
  },
  methods: {
    async rowSave(form, hide, closeLoading) {
      let res = await awaitResolve(
        createRefundSeason(form)
      )
      if (!res) return closeLoading()
      this.success()
      hide()
    },
    async rowUpdate(form, index, hide, closeLoading) {
      let res = await awaitResolve(
        updateRefundSeason(form)
      )
      if (!res) return closeLoading()
      this.success()
      hide()
    },
    async rowDel(row, index, hide) {
      let res = await awaitResolve(
        deleteRefundSeason({
          idList: [row.id]
        })
      )
      if (!res) return
      this.success()
      hide()
    },
    
    success() {
      this.$message.success('操作成功')
      this.init()
    }
  }
}
</script>

<style lang="scss">
</style>
